var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"headerBar"},[_vm._m(0),_c('div',{staticClass:"center"},[_c('div',{staticClass:"buttons"},[_c('i',{staticClass:"iconfont icon-arrow-left-bold back",on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('i',{staticClass:"iconfont icon-arrow-right-bold forward",on:{"click":function($event){return _vm.$router.go(1)}}})]),_c('div',{staticClass:"search"},[_c('el-popover',{attrs:{"placement":"bottom","width":"300","popper-class":"searchPop","trigger":"focus"},model:{value:(_vm.isSearchPopShow),callback:function ($$v) {_vm.isSearchPopShow=$$v},expression:"isSearchPopShow"}},[_c('el-input',{attrs:{"slot":"reference","placeholder":"请输入内容","prefix-icon":"el-icon-search","size":"mini"},on:{"input":_vm.inputSearch},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSubmit($event)}},slot:"reference",model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),(!_vm.searchSuggestList.songs)?_c('div',{staticClass:"hotSearch"},[_c('div',{staticClass:"hotSearchTitle"},[_vm._v("热搜榜")]),_vm._l((_vm.hotSearchList),function(item,index){return _c('div',{key:index,staticClass:"hotSearchItem",on:{"click":function($event){return _vm.clickHotSearchItem(item.searchWord)}}},[_c('div',{staticClass:"hotSearchIndex",class:index < 3 ? 'topThree' : ''},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"hotSearchInfo"},[_c('div',{staticClass:"hotSearchWord",class:index < 3 ? 'hotSearchWordTopThree' : ''},[_vm._v(" "+_vm._s(item.searchWord)+" ")]),_c('div',{staticClass:"hotSearchContent"},[_vm._v(_vm._s(item.content))])])])})],2):_vm._e(),_c('div',{staticClass:"searchSuggest"},[(_vm.searchSuggestList.songs)?_c('div',{staticClass:"hotSearchTitle"},[_vm._v(" 搜索建议 ")]):_vm._e(),(
              _vm.searchSuggestList.songs && _vm.searchSuggestList.songs.length != 0
            )?_c('div',{staticClass:"searchSuggestItem"},[_c('div',{staticClass:"searchSuggestItemTitle"},[_c('i',{staticClass:"iconfont icon-yinle"}),_vm._v(" 单曲 ")]),_vm._l((_vm.searchSuggestList.songs),function(item,index){return _c('div',{key:index,staticClass:"suggestItemDetail",on:{"click":function($event){return _vm.clickSuggestSong(item.id)}}},[_vm._v(" "+_vm._s(item.name + " - " + item.artists[0].name)+" ")])})],2):_vm._e(),(
              _vm.searchSuggestList.artists &&
              _vm.searchSuggestList.artists.length != 0
            )?_c('div',{staticClass:"searchSuggestItem"},[_c('div',{staticClass:"searchSuggestItemTitle"},[_c('i',{staticClass:"iconfont icon-mic"}),_vm._v(" 歌手 ")]),_vm._l((_vm.searchSuggestList.artists),function(item,index){return _c('div',{key:index,staticClass:"suggestItemDetail",on:{"click":function($event){return _vm.clickSuggestSinger(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2):_vm._e(),(
              _vm.searchSuggestList.albums && _vm.searchSuggestList.albums.length != 0
            )?_c('div',{staticClass:"searchSuggestItem"},[_c('div',{staticClass:"searchSuggestItemTitle"},[_c('i',{staticClass:"iconfont icon-more"}),_vm._v(" 专辑 ")]),_vm._l((_vm.searchSuggestList.albums),function(item,index){return _c('div',{key:index,staticClass:"suggestItemDetail",on:{"click":function($event){return _vm.clickSuggestAlbum(item.id)}}},[_vm._v(" "+_vm._s(item.name + " - " + item.artist.name)+" ")])})],2):_vm._e(),(
              _vm.searchSuggestList.playlists &&
              _vm.searchSuggestList.playlists.length != 0
            )?_c('div',{staticClass:"searchSuggestItem"},[_c('div',{staticClass:"searchSuggestItemTitle"},[_c('i',{staticClass:"iconfont icon-gedan"}),_vm._v(" 歌单 ")]),_vm._l((_vm.searchSuggestList.playlists),function(item,index){return _c('div',{key:index,staticClass:"suggestItemDetail",on:{"click":function($event){return _vm.clickSuggestMusicList(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2):_vm._e()])],1)],1)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"user"},[_c('div',{staticClass:"avatar"},[(!_vm.userInfo.avatarUrl)?_c('el-popover',{attrs:{"placement":"bottom","width":"300","trigger":"click"},model:{value:(_vm.isPopoverShow),callback:function ($$v) {_vm.isPopoverShow=$$v},expression:"isPopoverShow"}},[(_vm.isPopoverShow)?_c('login',{attrs:{"isRegisteredShow":_vm.isRegisteredShow},on:{"update:isRegisteredShow":function($event){_vm.isRegisteredShow=$event},"update:is-registered-show":function($event){_vm.isRegisteredShow=$event},"getUserInfo":function (info) { _vm.userInfo = info; _vm.isPopoverShow = false; }}}):_vm._e(),_c('img',{attrs:{"slot":"reference","src":require("assets/img/test.jpg"),"alt":""},slot:"reference"})],1):_c('img',{attrs:{"slot":"reference","src":_vm.userInfo.avatarUrl,"alt":""},on:{"click":_vm.goToPersonal},slot:"reference"})],1),(_vm.userInfo.nickname)?_c('div',{staticClass:"userName"},[_vm._v(" "+_vm._s(_vm.userInfo.nickname)+" ")]):_c('div',{staticClass:"userName"},[_vm._v("点击头像登录")])])]),_c('registered',{attrs:{"isDialogShow":_vm.isRegisteredShow},on:{"closeRegistered":_vm.closeRegistered}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("assets/img/logo.png"),"alt":""}})])}]

export { render, staticRenderFns }